.App {
    padding: 0;
    background-color: rgb(249, 250, 251);
}

.section-title{
    color: rgb(75, 85, 99);
    padding-top: 10px;
}

.section {
    padding-top: 15px;
}

/* HEADER */

.header-section {
    padding-bottom: 7px;
}

.Header {
    background-color: rgb(249, 250, 251);
    height: 90px;
}

.site-title {
    color: rgb(75, 85, 99);
}

.header-button {
    margin-left: 5px;
}

@media (min-width: 576px) {
    .header-form {
        width: 600px;
    }
}

.header-icon {
    margin-right: 2px;
}

.categorie-dropdown{
    color: white;
    text-decoration: none;
    font-size: 18px;
}

.categorie-dropdown:hover {
    color: white;
    text-decoration: none;
}

.categorie-icon {
    padding-left: 5px;
    padding-right: 5px;
}

.top-whatsapp {
    background-color: rgb(21, 128, 61);
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 2px;
}

.top-mail {
    background-color: rgb(3, 105, 161);
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 2px;
}

.top-icon {
    padding: 2px 2px 2px 2px;
    font-size: 22px;
    color: white;
}

.categorie-top-section{
   background-color: rgb(245, 158, 11);
   padding: 5px 30px 5px 30px;
}

.content-top-section{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    place-items: end normal;
}

.hidden-top-section{
    margin-right: auto;
}

.contact-top-section{
    display: flex;
    margin-left: auto;
    place-items: center normal;
}

.contact-top-section span{
    margin-left: 15px;
}

.image-top-section{
    padding-right: 5px;
}

.number-top-section{
    color: white;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    font-weight: 700;
    padding-top: 7px;
}

.title-top-section{
    color: white;
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
    font-weight: 700;
}

.categorie-section{
   background-color: rgb(14, 165, 233);
   padding: 1px 0px 1px 0px;
}

.categories-menu{
    display: flex;
    place-items: baseline normal;
}

.mobile-icon, .mobile-icon-badge, .mobile-contact-section{
    display: none;
}

.dropdown-offcanvas{
    display: none;
}

/* HEADER MOBILE */

@media (max-width: 1399px) {
    
    .site-title {
        font-size: 18px;
    }

    .header-form {
        width: 390px;
        padding: 1px;
    }
}

@media (max-width: 992px) {
    .mobile-contact-section{
        display: none;
    }
    
    .categorie-top-section {
        background-color: rgb(245, 158, 11);
        padding: 5px 1px 5px 1px;
    }
    
    .header-image {
        height: 120px;
        width: 120px;
        padding: 0;
    }
    
    .header-brand {
        padding:0;
    }
    
    .Header Container {
        padding-top:1px;
    }
    
    .Header {
        background-color: rgb(249, 250, 251);
        height: 200px;
        display: flex; 
        flex-flow: column;
        flex-wrap: wrap;
        margin-top: 1px;
        padding: 0;
    }
    
    .header-brand {
        order: 1;
        padding-right: 50px;
    }
    
    .header-form {
        order: 3;
        width: 1200px;
        padding: 20px;
        background-color: rgb(224, 242, 254);
    }
    
    .header-cart-shopping {
        order: 2;
    }
    
    .mobile-icon-div {
        position: relative;
    }
    
    .mobile-icon {
        display: block;
        color: gray;
        font-size: 28px;
        padding-right: 10px;
        position: relative;
    }
    
    .mobile-icon-badge {
        display: block;
        margin-top: -10px;
        margin-right: 1px;
        position:absolute;
        top:0;
        right:0; 
    }
    
    .web-icon {
        display: none;
    }
    
    .offcanvas-header{
        background-color: rgb(186, 230, 253);
    }
    
    .dropdown-offcanvas{
        display: block;
        width: 130px;
    }
    
    .menu-icon {
       font-size: 26px; 
    }
    
    #dropdown-button-drop-end{
        text-decoration: none;
        color: rgb(107, 114, 128);
        font-size: 20px;
    }
    
    .categorie-section{
        display: none;
    }
}

@media (max-width: 767px) {
    .title-top-section, .hidden-top-section{
        display: none;
    }
    
    .content-top-section{
        align-items: center;
        display: flex;
        justify-content: center;
    }
    
    .top-whatsapp {
        background-color: rgb(21, 128, 61);
        padding-left: 3px;
        padding-right: 3px;
        margin-right: 2px;
    }

    .top-mail {
        background-color: rgb(3, 105, 161);
        padding-left: 3px;
        padding-right: 3px;
        margin-right: 2px;
    } 
    
    .top-icon {
        color: white;
        font-size: 16px;
        font-weight: 600;
    }
    
    .categorie-top-section{
        background-color: rgb(245, 158, 11);
        padding: 5px 1px 5px 1px;
    }
    
    .header-image{
        height: 100px;
        width: 100px;
        padding: 0;
    }
    
    .header-brand{
        padding:0;
    }
    
    .Header Container{
        padding-top:1px;
    }
    
    .Header {
        background-color: rgb(249, 250, 251);
        height: 160px;
        display: flex; 
        flex-flow: column;
        flex-wrap: wrap;
        margin-top: 1px;
        padding: 0;
    }
    
    .header-brand {
        order: 1;
        padding-right: 1px;
    }
    
    .header-form {
        order: 3;
        width: 600px;
        padding: 10px;
        background-color: rgb(224, 242, 254);
    }
    
    .header-cart-shopping {
        order: 2;
    }
    
    .number-top-section{
        padding-left: 1px;
        color: white;
        font-size: 12px;
    }
    
    .site-titles {
        display: none;
    }
    
    .mobile-icon-div {
        position: relative;
    }
    
    .mobile-icon {
        display: block;
        color: gray;
        font-size: 28px;
        padding-right: 10px;
        position: relative;
    }
    
    .mobile-icon-badge {
        display: block;
        margin-top: -10px;
        margin-right: 1px;
        position:absolute;
        top:0;
        right:0; 
    }
    
    .web-icon {
        display: none;
    }
    
    .offcanvas-header{
        background-color: rgb(186, 230, 253);
    }
    
    .dropdown-offcanvas{
        display: block;
        width: 130px;
    }
    
    .menu-icon {
       font-size: 26px; 
    }
    
    #dropdown-button-drop-end{
        text-decoration: none;
        color: rgb(107, 114, 128);
        font-size: 20px;
    }
    
    .categorie-section{
        display: none;
    }
}

@media (max-width: 576px) {
    .header-form {
        padding-bottom: 10px;
    }
}

@media (max-width: 417px) {
    .title-top-section, .hidden-top-section{
        display: none;
    }
    
    .content-top-section {
        display: flex;
        justify-content: space-between;
    }
    
    .contact-top-section span{
        margin-left: 7px;
    }
    
    .top-whatsapp {
        background-color: rgb(21, 128, 61);
        padding: 3px 3px 0 3px;
        margin-right: 2px;
    }

    .top-mail {
        background-color: rgb(3, 105, 161);
        padding: 3px 3px 0 3px;
        margin-right: 2px;
    } 
    
    .top-icon {
        color: white;
        font-size: 13px;
        font-weight: 600;
    }
    
    .number-top-section {
        padding-left: 1px;
        color: white;
        font-size: 12px;
        font-weight: 600;
    }
    
    .categorie-top-section {
        background-color: rgb(245, 158, 11);
        padding: 5px 1px 5px 1px;
    }
    
    .header-image {
        height: 80px;
        width: 80px;
        padding: 0;
    }
    
    .header-brand{
        padding:0;
    }
    
    .Header Container{
        padding-top:1px;
    }
    
    .Header {
        background-color: rgb(249, 250, 251);
        height: 160px;
        display: flex; 
        flex-flow: column;
        flex-wrap: wrap;
        margin-top: 1px;
        padding: 0;
    }
    
    .header-brand {
        order: 1;
        padding-right: 1px;
    }
    
    .header-form {
        order: 3;
        width: 600px;
        padding: 10px;
        background-color: rgb(224, 242, 254);
    }
    
    .header-cart-shopping {
        order: 2;
    }
    
    .site-title {
        font-size: 18px;
    }
    
    .web-icon {
        display: none;
    }
    
    .offcanvas-header{
        background-color: rgb(186, 230, 253);
    }
    
    .dropdown-offcanvas{
        display: block;
        width: 130px;
    }
    
    .menu-icon {
       font-size: 26px; 
    }
    
    #dropdown-button-drop-end{
        text-decoration: none;
        color: rgb(107, 114, 128);
        font-size: 20px;
    }
    
    .categorie-section{
        display: none;
    }
}

@media (max-width: 382px) {
    .title-top-section, .hidden-top-section{
        display: none;
    }
    
    .content-top-section {
        display: flex;
        justify-content: space-between;
        padding-left: 0px;
    }
    
    .contact-top-section span{
        margin-left: 5px;
    }
    
    .top-whatsapp {
        background-color: rgb(21, 128, 61);
        padding: 3px 3px 0 3px;
        margin-right: 2px;
    }

    .top-mail {
        background-color: rgb(3, 105, 161);
        padding: 3px 3px 0 3px;
        margin-right: 2px;
    } 
    
    .top-icon {
        color: white;
        font-size: 12px;
        font-weight: 600;
    }
    
    .number-top-section {
        padding-left: 1px;
        color: white;
        font-size: 11px;
        font-weight: 600;
    }
    
    .categorie-top-section {
        background-color: rgb(245, 158, 11);
        padding: 5px 1px 5px 1px;
    }
    
    .header-image {
        height: 80px;
        width: 80px;
        padding: 0;
    }
    
    .header-brand{
        padding:0;
    }
    
    .Header Container{
        padding-top:1px;
    }
    
    .Header {
        background-color: rgb(249, 250, 251);
        height: 160px;
        display: flex; 
        flex-flow: column;
        flex-wrap: wrap;
        margin-top: 1px;
        padding: 0;
    }
    
    .header-brand {
        order: 1;
        padding-right: 1px;
    }
    
    .header-form {
        order: 3;
        width: 600px;
        padding: 10px;
        background-color: rgb(224, 242, 254);
    }
    
    .header-cart-shopping {
        order: 2;
    }
    
    .site-title {
        font-size: 16px;
    }
    
    .web-icon {
        display: none;
    }
    
    .offcanvas-header{
        background-color: rgb(186, 230, 253);
    }
    
    .dropdown-offcanvas{
        display: block;
        width: 130px;
    }
    
    .menu-icon {
       font-size: 26px; 
    }
    
    #dropdown-button-drop-end{
        text-decoration: none;
        color: rgb(107, 114, 128);
        font-size: 20px;
    }
    
    .categorie-section{
        display: none;
    }
    
    .header-icon {
        font-size: 22px;
    }
}


/* HERO */

.hero-section {
    background-color: rgb(3, 105, 161);
    padding-top: 2px;
    height: 330px;
}

@media (max-width: 1199px) {
    .hero-section {
        background-color: rgb(3, 105, 161);
        padding-top: 20px;
        padding-bottom: 200px;
        height: 340px;
    }
    
    .hero-text{
        font-size: 1.2rem; /* 16px */
        line-height: 1.5rem; /* 24px */
        font-weight: 600;
        color: rgb(248, 250, 252);
    }
}

@media (max-width: 992px) {
    .hero-section {
        background-color: rgb(3, 105, 161);
        padding-top: 20px;
        padding-bottom: 200px;
        height: 330px;
    }
    
    .hero-text{
        font-size: 1rem; /* 16px */
        line-height: 1.5rem; /* 24px */
        font-weight: 600;
        color: rgb(248, 250, 252);
    }
}

@media (max-width: 576px) {
    .hero-section {
        background-color: rgb(3, 105, 161);
        padding-bottom: 200px;
        height: 420px;
    }
    
    .hero-text{
        font-size: 1rem; /* 16px */
        line-height: 1.5rem; /* 24px */
        font-weight: 600;
        color: rgb(248, 250, 252);
    }
}

@media (max-width: 389px) {
    .hero-section {
        background-color: rgb(3, 105, 161);
        padding-bottom: 200px;
        height: 460px;
    }
    
    .hero-title{
        font-size: 1.90rem;
    }
    
    .hero-text{
        font-size: 1rem; /* 16px */
        line-height: 1.5rem; /* 24px */
        font-weight: 600;
        color: rgb(248, 250, 252);
    }
}

/* NEWEST SECTION */

.newestBooksSection {
    padding-top: 30px;
}

.mostNewestCard {
    position: relative;
    margin-right: 52px;
    width: 330px;
}

.mostNewestCardImage {
    background-color: aquamarine;
    width: 100%;
    overflow: hidden;
    height: 35rem;
    border-radius: 0.375rem; /* 6px */
}

.mostNewestImage {
    max-height: 35rem;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.mostNewestBookInfo {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.mostNewestBookTitle {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    color: rgb(55, 65, 81);
}

.mostNewestBookTitleLink {
    inset: 0px;
    position: absolute;
}

.mostNewestBookAuthor {
    margin-top: 1px;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    color: rgb(107, 114, 128);
}

.mostNewestBookPrice {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    font-weight: 500;
    color: rgb(17, 24, 39); 
}

@media (min-width: 1400px) {
    .book {
        position: relative;
        margin-right: 14px;
        width: 210px;
        height: 410px;
        padding: 10px;
    }

    .bookCard {
        position: relative;
        width: 190px;
    }

    .bookCardImage {
        width: 100%;
        overflow: hidden;
        height: 14rem;
        border-radius: 0.375rem; /* 6px */
    }

    .bookImage {
        /*max-height: 100%;*/
        /*width: auto;*/
        height: 15rem;
        width: 100%;
        object-fit: contain;
        object-position: center;
    }

    .bookInfo {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
    }

    .bookTitle {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(55, 65, 81);
    }

    .bookTitleLink {
        inset: 0px;
        position: absolute;
    }

    .bookAuthor {
        margin-top: 1px;
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(107, 114, 128);
    }

    .bookPrice {
        font-size: 1rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        font-weight: 900;
        color: #fd7e14;
    }
    
    .buttonAddToFavoris {
        color: #fd7e14;
        /*display: inline-block;*/
        background: white;
        border-radius: 50%;
        box-shadow: 0 0 2px #fd7e14;
        padding: 0.5em 0.6em;
        font-size: 22px;
        margin-top: -10px;
        margin-right: -5px;
        position:absolute;
        top:0;
        right:0;
        z-index: 1;
        display: none;
    }
 
    .bookButton {
        position:absolute;
        margin-bottom: 50px;
        width: 100%;
        display: none;
    }
    
    .bookButtondiv {
        padding: 10px 0px 1px 0px;
    }
    
    .book:hover {
        background-color: rgb(228, 228, 231);
    }
    
    .book:hover .bookButton{
        display: inline-block;
        background-color: #fd7e14;
        z-index: 1;
    }
    
    .book:hover .bookButtondiv{
        padding-bottom: 20px;
    }
    
    .bookButton:hover{
        display: inline-block;
        cursor: pointer;
    }
    
    .book:hover .buttonAddToFavoris{
        display: inline-block;
        z-index: 1;
    }
    
    .buttonAddToFavoris:hover {
        box-shadow: 0 0 12px #fd7e14;
        border: solid #fd7e14;
        cursor: pointer;
    }
}

@media (max-width: 1399px) {
    .book {
        position: relative;
        margin-right: 14px;
        width: 200px;
        height: 380px;
        padding: 8px;
    }

    .bookCard {
        position: relative;
        width: 180px;
    }

    .bookCardImage {
        width: 100%;
        overflow: hidden;
        height: 13rem;
        border-radius: 0.375rem; /* 6px */
    }

    .bookImage {
        /*max-height: 100%;*/
        /*width: auto;*/
        height: 14rem;
        width: 100%;
        object-fit: contain;
        object-position: center;
    }

    .bookInfo {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
    }

    .bookTitle {
        font-size: 0.845rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(55, 65, 81);
    }

    .bookTitleLink {
        inset: 0px;
        position: absolute;
    }

    .bookAuthor {
        margin-top: 1px;
        font-size: 0.845rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(107, 114, 128);
    }

    .bookPrice {
        font-size: 1rem; /* 14px */
        line-height: 1.15rem; /* 20px */
        font-weight: 900;
        color: #fd7e14;
    }
    
    .buttonAddToFavoris {
        color: #fd7e14;
        /*display: inline-block;*/
        background: white;
        border-radius: 50%;
        box-shadow: 0 0 2px #fd7e14;
        padding: 0.5em 0.6em;
        font-size: 22px;
        margin-top: -10px;
        margin-right: -5px;
        position:absolute;
        top:0;
        right:0;
        display: none;
    }
 
    .bookButton {
        position:absolute;
        margin-bottom: 50px;
        width: 100%;
        display: none;
    }
    
    .bookButtondiv {
        padding: 10px 0px 1px 0px;
    }
    
    .book:hover {
        background-color: rgb(228, 228, 231);
    }
    
    .book:hover .bookButton{
        display: inline-block;
        background-color: #fd7e14;
        z-index: 1;
    }
    
    .book:hover .bookButtondiv{
        padding-bottom: 20px;
    }
    
    .bookButton:hover{
        display: inline-block;
        cursor: pointer;
    }
    
    .book:hover .buttonAddToFavoris{
        display: inline-block;
        z-index: 1;
    }
    
    .buttonAddToFavoris:hover {
        box-shadow: 0 0 12px #fd7e14;
        border: solid #fd7e14;
        cursor: pointer;
    }
}

@media (max-width: 1199px) {
    .book {
        position: relative;
        margin-right: 14px;
        width: 210px;
        height: 380px;
        padding: 10px;
    }

    .bookCard {
        position: relative;
        width: 190px;
    }

    .bookCardImage {
        width: 100%;
        overflow: hidden;
        height: 14rem;
        border-radius: 0.375rem; /* 6px */
    }

    .bookImage {
        /*max-height: 100%;*/
        /*width: auto;*/
        height: 15rem;
        width: 100%;
        object-fit: contain;
        object-position: center;
    }

    .bookInfo {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
    }

    .bookTitle {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(55, 65, 81);
    }

    .bookTitleLink {
        inset: 0px;
        position: absolute;
    }

    .bookAuthor {
        margin-top: 1px;
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(107, 114, 128);
    }

    .bookPrice {
        font-size: 1rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        font-weight: 900;
        color: #fd7e14;
    }
    
    .buttonAddToFavoris {
        color: #fd7e14;
        /*display: inline-block;*/
        background: white;
        border-radius: 50%;
        box-shadow: 0 0 2px #fd7e14;
        padding: 0.5em 0.6em;
        font-size: 22px;
        margin-top: -10px;
        margin-right: -5px;
        position:absolute;
        top:0;
        right:0;
        display: none;
    }
 
    .bookButton {
        position:absolute;
        margin-bottom: 50px;
        width: 100%;
        display: none;
    }
    
    .bookButtondiv {
        padding: 10px 0px 1px 0px;
    }
    
    .book:hover {
        background-color: rgb(228, 228, 231);
    }
    
    .book:hover .bookButton{
        display: inline-block;
        background-color: #fd7e14;
        z-index: 1;
    }
    
    .book:hover .bookButtondiv{
        padding-bottom: 20px;
    }
    
    .bookButton:hover{
        display: inline-block;
        cursor: pointer;
    }
    
    .book:hover .buttonAddToFavoris{
        display: inline-block;
        z-index: 1;
    }
    
    .buttonAddToFavoris:hover {
        box-shadow: 0 0 12px #fd7e14;
        border: solid #fd7e14;
        cursor: pointer;
    }
}

@media (max-width: 576px) {
    
    .newestBooksSection {
        padding-top: 10px;
    }
    
    .book {
        position: relative;
        margin-right: 14px;
        width: 210px;
        height: 380px;
        padding: 10px;
    }

    .bookCard {
        position: relative;
        margin-right: 14px;
        width: 150px;
    }

    .bookCardImage {
        width: 100%;
        overflow: hidden;
        height: 12rem;
        border-radius: 0.375rem; /* 6px */
    }

    .bookImage {
        /*max-height: 100%;*/
        /*width: auto;*/
        height: 13rem;
        width: 100%;
        object-fit: contain;
        object-position: center;
    }

    .bookInfo {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
    }

    .bookTitle {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(55, 65, 81);
    }

    .bookTitleLink {
        inset: 0px;
        position: absolute;
    }

    .bookAuthor {
        margin-top: 1px;
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(107, 114, 128);
    }

    .bookPrice {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        font-weight: 900;
        color: #fd7e14;
    }
    
    .buttonAddToFavoris {
        color: #fd7e14;
        z-index: 1;
        /*display: inline-block;*/
        background: white;
        border-radius: 50%;
        box-shadow: 0 0 2px #fd7e14;
        padding: 0.5em 0.6em;
        font-size: 22px;
        margin-top: -10px;
        margin-right: -5px;
        position:absolute;
        top:0;
        right:0;
    }
 
    .bookButton {
        position:absolute;
        margin-bottom: 50px;
        width: 100%;
        background-color: #fd7e14;
    }
    
    .bookButton:disabled, .buttonBookDetail[disabled] {
        background-color: rgb(253, 186, 116);
        border-color: rgb(229, 231, 235);
    }
    
    .bookButtondiv {
        padding: 10px 0px 20px 0px;
    }
    
    .buttonAddToFavoris:hover {
        box-shadow: 0 0 12px #fd7e14;
        border: solid #fd7e14;
        cursor: pointer;
    }
}

/*.ribbon {
    --f: 10px;
    --r: 15px;
    --t: 10px;
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: 
        polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%, 
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)), 
        var(--r) calc(50% - var(--f)/2));
    background: #fd7e14;;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    color: white;
    font-weight: 800;
}*/

.ribbon {
    z-index: 10;
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: absolute;

    &:before,
    &:after {
      position: absolute;
      z-index: -1;
      content: "";
      display: block;
      border: 5px solid #fd7e14;
    }
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #fd7e14;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
}

.ribbon-top-right {
    top: -10px;
    right: -10px;

    &:before,
    &:after {
      border-top-color: transparent;
      border-right-color: transparent;
    }

    &:before {
      top: 0;
      left: 0;
    }

    &:after {
      bottom: 0;
      right: 0;
    }
}

.ribbon-top-right span {
    left: -34px;
    top: 18px;
    transform: rotate(45deg);
}

.modal-title{
    font-size: 18px;
}

.bodyModal{
    margin: auto;
    padding: auto;
}

.bookModal{
    width: 210px;
    height: 360px;    
}

.bookCardModal{
    position: relative;
    width: 200px;
}

.bookCardImageModal {
    width: 100%;
    overflow: hidden;
    height: 15rem;
    border-radius: 0.375rem; /* 6px */
}

.bookImageModal {
    /*max-height: 100%;*/
    /*width: auto;*/
    height: 15rem;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.bookInfoModal {
    margin-top: 12px;
    text-align: center;
}

.bookTitleModal {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
    color: rgb(55, 65, 81);
}

.bookAuthorModal {
    margin-top: 1px;
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    color: rgb(107, 114, 128);
}

/* BOOK DETAILS STYLE */

.bookDetails {
    padding-top: 50px;
}

.bookPrincipal {
    padding-bottom: 20px;
    position: relative
}

.bookSecondary {
    padding-left: 20px;
}

.bookPrincipalCover {
    max-height: 26rem;
    width: 100%;
    object-fit: contain;
    object-position: center;
    position: relative;
}

.bookSecondaryContainer {
    border: solid rgb(229, 231, 235);
    border-width: 1px;
    background-color: white;
    width: 100%;
    overflow: hidden;
    height: 11rem;
    border-radius: 0.375rem; /* 6px */
}

.bookSecondaryCover {
    width: 100%;
    object-fit: contain;
    object-position: center;
    max-height: 10rem;
    padding-top: 5px;
    padding-bottom: 5px;
}

.buttonShower {
    text-decoration: none;
    background-color: white;
    border: 1px solid white;
    max-height: 13rem;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.buttonShower:hover {
    text-decoration: none;
    background-color: white;
    border: white;
}

.buttonShower:active{
    background-color: red;
}

.bookDetailContainerRight {
    padding-top: 36px;
    padding-left: 30px;
}

.bookDetailTitle {
    font-size: 3.25rem;
    line-height: 1;
    color: rgb(55, 65, 81);
}

.bookDetailAuthor {
    color: rgb(55, 65, 81);
}

.bookDetailPrice {
    color: rgb(220, 38, 38);
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    padding-bottom: 10px;
}

.bookDetailOther {
    padding-top: 20px;
    padding-bottom: 20px;
}

.bookDetailDescription {
    padding: 40px 30px 30px 20px;
}

.bookDetailDescriptionBox {
    border: solid rgb(55, 65, 81);
    border-width: 1px;
    border-radius: 0.375rem; /* 6px */
}
.bookDetailDescriptionTitle{
    color: rgb(55, 65, 81);
}

.bookDetailDescriptionText {
    padding: 20px 20px 20px 20px;
    color: rgb(55, 65, 81);
}

.bookDetailRelated {
    padding-top: 40px;
    padding-bottom: 20px;
}

.bookDetailRelatedTitle {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid rgb(75, 85, 99); 
    line-height: 0.1em;
    margin: 10px 0 20px; 
}

.bookDetailRelatedTitle span {
    background:rgb(249, 250, 251);
    padding:0 10px; 
    color: rgb(55, 65, 81);
}

.buttonBookDetail{
    background-color: #fd7e14;
}

.buttonBookDetail:hover{
    background-color: #fd7e14;
    border-color:#fd7e14;
    height: 50px;
    width: 200px;   
}

.buttonBookDetail:disabled, .buttonBookDetail[disabled] {
    background-color: rgb(253, 186, 116);
    border-color: rgb(229, 231, 235);
}

hr.new4 {
    border: 1px solid black;
}

@media (max-width: 576px) {
    .bookSecondaryContainer {
        border: solid rgb(229, 231, 235);
        border-width: 1px;
        background-color: white;
        width: 100%;
        overflow: hidden;
        height: 7rem;
        border-radius: 0.375rem; /* 6px */
    }

    .bookSecondaryCover {
        width: 100%;
        object-fit: contain;
        object-position: center;
        max-height: 9rem;
        padding-top: 1px;
        padding-bottom: 1px;
    }
}

/* SHOPPING CART */

.cartShopping {
    padding-top: 40px;
    padding-bottom: 20px;
}

.cartShoppingTitle {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    color: rgb(55, 65, 81);
}

.cartShoppingTitle span {
    background: rgb(249, 250, 251);
    padding:0 10px; 
}

@media (min-width: 576px) {
    .shoppingXsScreen{
        display: none;
    }
    
    .shoppingEmpty {
        background-color: rgb(212, 212, 216);
        padding: 15px 30px 5px 30px;
        display: flex;
        justify-content: space-between;
    }
    
    .shoppingButtonEmpty {
        text-decoration: none;
        color: rgb(75, 85, 99);
    }
    
    .shoppingButtonEmpty:hover {
        color: rgb(31, 41, 55);
    }
    
    .shoppingItem {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 10px 30px;
        border-bottom: 2px solid rgb(212, 212, 216);
        background-color: rgb(244, 244, 245);
    }

    .shoppingLeft {
        display: flex;        
        align-items: center;
        justify-content: space-between;
        margin-right: auto;
    }

    .shoppingRigth {
        margin-left: auto;
    }

    .shoppingItemImage {
        height: 13rem;
        width: 10rem;
        object-fit: contain;
        object-position: center;
    }

    .shoppingItemInfo {
        margin: 0;
        padding: 0px 10px 0px 15px;
    }
    
    .shoppingItemTitle {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    
    .shoppingItemAuthor {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(75, 85, 99);
    }

    .shoppingItemQty {
        padding-top: 80px;
    }
    
    .shoppingItemQtyLibelle {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(75, 85, 99);
    }

    .shoppingItemDelete{
        padding-bottom: 150px;
        padding-left: 50px;
        color: rgb(82, 82, 91);
        font-size: 20px;
    }
    
    .shoppingItemDelete:hover {
        cursor: pointer;
        color: rgb(3, 7, 18);
        font-size: 24px;
    }
    
    .shoppingItemPriceLibelle{
        font-size: 1.3rem;
        line-height: 1.75rem;
        color: rgb(75, 85, 99);
    }
    
    .shoppingTotal {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 10px 30px;
    }
    
    .shoppingHr{
        border: 1px solid black;
    }
}

.subcontainer {
    width: 80%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media only screen and (max-width: 576px){
    .shoppingMdScreen{
        display: none;
    }
    
    .subcontainer {
        width: 95%;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .shoppingEmpty {
        background-color: rgb(234, 235, 235);
        padding: 10px 5px 10px 5px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        place-items: baseline normal;
    }
    
    .shoppingItemsNumber {
        color: rgb(75, 85, 99);
    }
    
    .shoppingButtonEmpty {
        text-decoration: none;
        border-width: 20px;
        border: 1px solid rgb(210, 212, 212);
        color: rgb(75, 85, 99);
        background-color: rgb(210, 212, 212);
    }
    
    .shoppingButtonEmpty:hover {
        color: rgb(31, 41, 55);
    }
    
    .shoppingItem {
        display: flex;
        align-items: center;
        justify-items: start;
        margin: 0 auto;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    .shoppingItemImage {
        height: 11rem;
        width: 8rem;
        object-fit: contain;
        object-position: center;
    }
    .shoppingItemInfo{
        padding-left: 10px;
        width: 200px;
    }
    
    .shoppingItemTop{
        padding-bottom: 40px;
    }
    
    .shoppingItemBottom{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        place-items: baseline normal;
    }
    
    .shoppingItemTitle {
        font-size: 1.1rem;
        line-height: 1.5rem;
        color: rgb(55, 65, 81);
    }
    
    .shoppingItemAuthor {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(75, 85, 99);
    }
    
    .shoppingItemDelete{
        font-size: 22px;
        color: #fd7e14;
    }
    
    .shoppingItemPrice{
        font-size: 1.15rem;
        line-height: 1.55rem;
        color: rgb(75, 85, 99);
    }
    
    .shoppingTotal {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 10px 30px;
        color: rgb(55, 65, 81);
    }
    
    .shoppingHr{
        border: 1px solid black;
    }
}

.shoppingConditions{
    margin-top: 30px;
    padding: 20px;
    background-color: rgb(255, 247, 237);
}

.shoppingConditionsTitle{
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
    padding-bottom: 15px;
    font-weight: 600;
    color: rgb(55, 65, 81);
}

.shoppingConditionsText{
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    color: rgb(55, 65, 81);
}

.buttonShoppingValidate{
    background-color: #fd7e14;
}

.buttonShoppingValidate:hover{
    background-color: #fd7e14;
}

/* FAVORIS */

.favoris {
    padding-top: 40px;
    padding-bottom: 20px;
}

.favorisTitle {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    color: rgb(55, 65, 81);
}

.favorisTitle span {
    background: rgb(249, 250, 251);
    padding:0 10px; 
}

@media (min-width: 576px) {
    .favorisXsScreen{
        display: none;
    }
    
    .favorisEmpty {
        background-color: rgb(212, 212, 216);
        padding: 15px 30px 5px 30px;
        display: flex;
        justify-content: space-between;
    }
    
    .favorisButtonEmpty {
        text-decoration: none;
        color: rgb(75, 85, 99);
    }
    
    .favorisButtonEmpty:hover {
        color: rgb(31, 41, 55);
    }
    
    .favorisItem {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 10px 30px;
        border-bottom: 2px solid rgb(212, 212, 216);
        background-color: rgb(244, 244, 245);
    }

    .favorisLeft {
        display: flex;        
        align-items: center;
        justify-content: space-between;
        margin-right: auto;
    }

    .favorisRigth {
        margin-left: auto;
    }

    .favorisItemImage {
        height: 13rem;
        width: 10rem;
        object-fit: contain;
        object-position: center;
    }

    .favorisItemInfo {
        margin: 0;
        padding: 0px 10px 0px 15px;
    }
    
    .favorisItemTitle {
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: rgb(55, 65, 81);
    }
    
    .favorisItemAuthor {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(75, 85, 99);
    }

    .favorisItemPrice {
        padding-top: 80px;
    }
    
    .favorisItemPriceLibelle {
        font-size: 1.3rem;
        line-height: 1.75rem;
        color: rgb(75, 85, 99);
    }

    .favorisItemDelete {
        padding-bottom: 50px;
        padding-left: 130px;
        color: rgb(82, 82, 91);
        font-size: 24px;
    }
    
    .favorisItemDelete:hover {
        cursor: pointer;
        color: rgb(3, 7, 18);
        font-size: 26px;
    }
    
    .favorisButtonAdd {
        background-color: rgb(245, 158, 11);
        border-color: rgb(229, 231, 235);
    }
    
    .favorisButtonAdd:hover {
        background-color: rgb(217, 119, 6);
        border-color: rgb(229, 231, 235);
    }
    
    .favorisButtonAdd:disabled, .favorisButtonAdd[disabled] {
        background-color: rgb(253, 186, 116);
        border-color: rgb(229, 231, 235);
    }
}

@media (max-width: 992px) {
    .favorisButtonAdd {
        width: 160px;
    }
    
    .subcontainer {
        width: 95%;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (max-width: 576px) {
    .favorisMdScreen{
        display: none;
    }
    
    .subcontainer {
        width: 95%;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .favorisEmpty {
        background-color: rgb(234, 235, 235);
        padding: 10px 5px 10px 5px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        place-items: baseline normal;
    }
    
    .favorisItemsNumber {
        color: rgb(75, 85, 99);
    }
    
    .favorisButtonEmpty {
        text-decoration: none;
        border-width: 20px;
        border: 1px solid rgb(210, 212, 212);
        color: rgb(75, 85, 99);
        background-color: rgb(210, 212, 212);
    }
    
    .favorisItem {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 2px solid rgb(212, 212, 216);
        background-color: rgb(244, 244, 245);
    }
    
    .favorisItemImage {
        height: 11rem;
        width: 8rem;
        object-fit: contain;
        object-position: center;
    }
    
    .favorisItemInfo {
        padding-left: 10px;
        width: 300px;
    }
    
    .favorisItemTop {
        padding-bottom: 40px;
    }
    
    .favorisItemBottom{
        margin-top: 1px;
        display: flex;
        justify-content: space-between;
        place-items: baseline normal;
    }
    
    .favorisItemTitle {
        font-size: 1.1rem;
        line-height: 1.5rem;
        color: rgb(55, 65, 81);
    }
    
    .favorisItemAuthor {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        color: rgb(75, 85, 99);
    }
    
    .favorisItemPrice {
        font-size: 1.15rem;
        line-height: 1.25rem;
        color: rgb(75, 85, 99);
    }

    .favorisItemDelete {
        color: rgb(82, 82, 91);
        font-size: 24px;
    }
    
    .favorisAddToCart {
        color: rgb(245, 158, 11);
        font-size: 24px;
    }
    
    .favorisAddToCartDisabled {
        color: rgb(253, 186, 116);
        font-size: 24px;
    }
}

/* PAGINATION */

@media (min-width: 576px) {
    .paginationSMScreen{
        display: none;
    }
}

@media (max-width: 576px) {
    .paginationLGScreen{
        display: none;
    }
}

/* FOOTER STYLE */

.footer-section {
    padding-bottom: 1.5rem; /* 24px */
}

.footerInner {
    max-width: 1280px;
    padding: 4px;
    padding-top: 1.5rem; /* 24px */
    padding-bottom: 1.5rem; /* 24px */
    margin-left: auto;
    margin-right: auto;
}

.footerHr {
    border-color: rgb(229, 231, 235);
    margin-top: 2rem; /* 32px */
    margin-bottom: 2rem; /* 32px */
}

.footerLogo {
    display: flex;
    align-items: center;
    justify-items: center;
    margin-bottom: 0.75rem; /* 12px */
}

.footerCopyright {
    display: block;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    color: rgb(107, 114, 128);
}

.footerSocial {
    margin-top: 1.25rem; /* 20px */
    margin-bottom: 1.25rem; /* 20px */
    list-style-type: none;  
}

.footerSocialLink {
    color: rgb(107, 114, 128);
    
}

.footerSocialLink:hover {
    color: rgb(17, 24, 39);
}

.footerSocialSvg {
    width: 1.25rem; /* 20px */
    height: 1.25rem; /* 20px */
}

.page-item.active .page-link {
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.page-link {
  color: #fd7e14;
}

.page-link:hover {
  color: #fd7e14;
}